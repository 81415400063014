import React from "react";
import CoinBackground from "../../components/backgrounds/CoinBackground";
import Footer from "../../components/home/Footer";

const About = () => {
  return (
    <>
      <div className="w-full min-h-screen flex flex-col gap-4 bg-gradient-to-br from-black to-black/80 relative px-10 pt-[9vh]">
        <CoinBackground />
        <h2
          data-aos="fade-up"
          className="font-semibold text-5xl max-lg:text-3xl text-primary w-full my-20 z-10"
        >
          About Us
        </h2>
        <p className="text-sm text-white text-left tracking-wide z-10">
          We are a Global Mining Service, we provide investors with a reliable
          source of high income by getting shared profits from our well
          organized mining rigs and offering high quality service. We are here
          to automate and simplify the relations between investors and profit
          making. We look forward to you being part of our community.
        </p>
        <p className="text-sm text-white text-left tracking-wide z-10">
          At QDI MINERS, we understand the growing demand for secure and
          profitable investment opportunities in the digital asset space. As a
          team of experienced professionals in both the financial and
          technological sectors, we have leveraged our expertise to create a
          robust platform that allows individuals from all over the world to
          participate in the lucrative world of Bitcoin mining.
        </p>
        <p className="text-sm text-white text-left tracking-wide">
          Our mining operations are strategically located in regions with access
          to abundant and cost-effective energy sources, ensuring optimal
          efficiency and profitability. We utilize state-of-the-art mining rigs
          and employ cutting-edge techniques to maximize the hash rate and yield
          of our operations.
        </p>
        <p className="text-sm text-white text-left tracking-wide z-10">
          Transparency and trust are at the core of our business values. We
          provide our investors with detailed insights into our mining
          activities, including real-time performance metrics and financial
          reports. Additionally, our customer support team is available around
          the clock to address any inquiries or concerns promptly.
        </p>
        <p className="text-sm text-white text-left tracking-wide z-10">
          By joining QDI MINERS, you gain access to a reliable and sustainable
          source of passive income. Whether you're a seasoned investor or new to
          the world of cryptocurrencies, our platform offers a user-friendly
          interface and intuitive investment tools to help you navigate the
          complexities of digital asset investing with ease.
        </p>
        <p className="text-sm text-white text-left tracking-wide z-10">
          We are committed to fostering a vibrant and inclusive community of
          like-minded individuals who share our passion for financial freedom
          and technological innovation. Together, we can reshape the future of
          finance through the power of Bitcoin mining.
        </p>
        <p className="text-sm text-white text-left tracking-wide z-10">
          Join us today and embark on a journey towards financial prosperity
          with QDI MINERS. We look forward to welcoming you to our growing
          community of investors and partners.
        </p>
        <p
          data-aos="fade-up"
          className="text-sm text-white text-right tracking-wide mb-10 z-10"
        >
          Happy mining!
        </p>
      </div>
      <Footer />
    </>
  );
};

export default About;
