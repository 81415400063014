import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "./Modal";

const BuyPlanModal = ({ id, item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Bitcoin");
  const navigate = useNavigate();

  const save = () => {
    sessionStorage.setItem("payment_method", paymentMethod);
    sessionStorage.setItem("amount", amount);
    sessionStorage.setItem("plan_id", id);

    if (paymentMethod === "Bitcoin") {
      closeModal();
      navigate("/make-deposit/btc");
    } else if (paymentMethod === "Litecoin") {
      closeModal();
      navigate("/make-deposit/lite");
    } else if (paymentMethod === "Ethereum") {
      closeModal();
      navigate("/make-deposit/eth");
    } else {
      closeModal();
      navigate("/make-deposit/usdt");
    }
  };

  const makeDeposit = (e) => {
    e.preventDefault();
    if (amount === "") {
      toast.warning("Please enter an amount to proceed");
    } else if (
      amount < parseInt(item.min_amount) ||
      amount > parseInt(item.max_amount)
    ) {
      toast.warning("Please enter a valid amount for this plan");
    } else {
      save();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <button
        onClick={openModal}
        className="bg-primary px-4 py-1.5 text-sm text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out w-full"
      >
        Buy
      </button>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form
          onSubmit={makeDeposit}
          className="border border-primary w-full p-5 bg-black shadow-md flex flex-col gap-2 items-center z-10"
        >
          <h1 className="text-xl font-semibold text-primary">Make a deposit</h1>
          <input
            type="number"
            className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <select
            className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40 appearance-none"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="Bitcoin">Bitcoin</option>
            <option value="USDT">USD Tether</option>
            <option value="Litecoin">Litecoin</option>
            <option value="Ethereum">Ethereum</option>
          </select>
          <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
            Deposit{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(amount)}
          </button>
        </form>
      </Modal>
    </>
  );
};

export default BuyPlanModal;
